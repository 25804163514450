import { getOrCreateDataObject } from 'o365.vue.ts';

export default getOrCreateDataObject({
    id: 'dsOMyReports',
    viewName: 'sviw_Reporting_MyReports',
    distinctRows: true,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    appendData: false,
    disableLayouts: true,
    selectFirstRowOnLoad: true,
    fields: [
        {
            name: 'ID',
            type: 'number'
        },
        {
            name: 'ReportID',
            type: 'number'
        },
        {
            name: 'Title',
            type: 'string'
        },
        {
            name: 'MenuGroup',
            type: 'string',
            sortOrder: 1,
            sortDirection: 'asc'
        },
        {
            name: 'IsBookmarked',
            type: 'bit'
        },
        {
            name: 'ViewName',
            type: 'string'
        }
    ],
    clientSideHandler: false,
    maxRecords: -1,
    dynamicLoading: false,
    whereClause: 'MenuGroup IS NOT NULL'
});
